import React from "react";
import { redirectToRegistrationForm } from "../utils/utils";
import { MainHeader } from "../componenets/header/MainHeader";
import { Footer } from "../componenets/footer/Footer";

const curriculumData = [
  {
    week: "Week 1: Programming Basics and Data Structures",
    description:
      "Introduction to C++ programming fundamentals, including variables, data types, operators, conditionals, functions, and basic data structures.",
  },
  {
    week: "Week 2: Relational Databases and SQL Basics",
    description:
      "Introduction to relational databases, SQL basics, table creation, and data manipulation.",
  },
  {
    week: "Week 3: NoSQL Databases and Data Modeling",
    description:
      "Exploration of NoSQL databases, data modeling concepts, and normalization techniques.",
  },
  {
    week: "Week 4: Indexing and Query Optimization",
    description:
      "Understanding indexing techniques and optimizing database queries for efficiency.",
  },
  {
    week: "Week 5: HTML and CSS Fundamentals",
    description:
      "Fundamental concepts of HTML and CSS, including document structure, common tags, and basic styling techniques.",
  },
  {
    week: "Week 6: Advanced CSS and Version Control with Git",
    description:
      "Exploring advanced CSS features, CSS preprocessors, and version control using Git for collaborative development.",
  },
  {
    week: "Week 7: JavaScript Fundamentals and DOM Manipulation",
    description:
      "Fundamental concepts of JavaScript, including variables, data types, control flow, and DOM manipulation.",
  },
  {
    week: "Week 8: Advanced JavaScript and ES6+ Features",
    description:
      "Exploring asynchronous JavaScript, ES6+ features, and error handling.",
  },
  {
    week: "Week 9: JavaScript Data Manipulation and Error Handling",
    description:
      "In-depth exploration of working with arrays, objects, and error handling techniques in JavaScript.",
  },
  {
    week: "Week 10: Introduction to React and Component Development",
    description:
      "Setting up React environment, understanding JSX, component development, and basic routing.",
  },
  {
    week: "Week 11: React Hooks and State Management with Redux",
    description:
      "Exploring React Hooks for state management and integrating Redux for application-wide state.",
  },
  {
    week: "Week 12: Full-Stack Project Setup and Integration",
    description:
      "Setting up the project environment, integrating frontend with backend, and planning full-stack projects.",
  },
  {
    week: "Week 13: Building the Backend and Authentication",
    description:
      "Setting up backend server with Node.js/Express, creating RESTful APIs, and implementing authentication.",
  },
  {
    week: "Week 14: Advanced React Techniques and API Integration",
    description:
      "Exploring advanced React techniques, styling components, and integrating backend APIs.",
  },
  {
    week: "Week 15: Final Project Development and Deployment",
    description:
      "Development of a final full-stack project, deployment preparation, and post-deployment monitoring.",
  },
];

export const Curriculum = () => {
  return (
    <>
      <MainHeader />
      <div className="bg-black text-white min-h-screen mt-[8rem] ">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-center mb-10">Curriculum</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {curriculumData?.map((weekData, index) => (
              <div
                key={index}
                className="bg-gray-900 p-6 rounded-lg shadow-lg relative overflow-hidden"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-purple-500 opacity-10 rounded-lg"></div>
                <h2 className="text-xl font-semibold mb-4 z-10 relative">
                  {weekData.week}
                </h2>
                <p className="text-gray-300 z-10 relative">
                  {weekData.description}
                </p>
                <div className="code-style z-10 relative mt-4">
                  <pre>
                    <code>{`{
    week: "${weekData.week}",
    description: "${weekData.description}"
    }`}</code>
                  </pre>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-8">
            <div
              className="w-full max-w-md bg-orange-500 hover:bg-orange-400 text-center p-4 text-lg mt-8 cursor-pointer rounded-full"
              onClick={redirectToRegistrationForm}
            >
              Get Started
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Curriculum;
