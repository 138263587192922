import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Home } from "./pages/Home";

import { RegistrationForm } from "./pages/RegistrationForm";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import { Sidebar } from "./componenets/sidebar/Sidebar";
import { useEffect } from "react";
import { Registrations } from "./pages/Registrations";
import { AuthenticatedRoutes } from "./componenets/authenticated-routes/AuthenticatedRoutes";
import { NotificationPage } from "./componenets/notification/NotificationPage";
import { PageNotFound } from "./componenets/page-not-found/PageNotFound";
import { getSession } from "./redux/services/authService";
import { Navbar } from "./componenets/navbar/Navbar";
import { PrivacyPolicy } from "./componenets/policy/PrivacyPolicy";
import { MainHeader } from "./componenets/header/MainHeader";
import { Footer } from "./componenets/footer/Footer";
import Curriculum from "./pages/Curriculum";
import { AdminNavigation } from "./componenets/navigation/AdminNavigation";
import { Navigation } from "./componenets/navigation/Navigation";

function App() {
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const { pathname } = useLocation();

  let custom_menu;
  if (isAuthenticated) {
    if (user.user.type === "admin") {
      custom_menu = <AdminNavigation />;
    } else {
      custom_menu = <Navigation />;
    }
  }

  useEffect(() => {
    dispatch(getSession());
  }, [dispatch]);

  const ADMIN_ROUTES = (
    <>
      <Route path="/registrations" element={<Registrations />} />
    </>
  );

  return (
    <div className="App">
      <div style={{ backgroundColor: "black" }}>
        <Layout>
          {isAuthenticated && <Sidebar menu={custom_menu} />}
          <Layout.Content className="content">
            {pathname !== "/" &&
              pathname !== "/privacy-policy" &&
              pathname !== "/curriculum" && <Navbar menu={custom_menu} />}

            <div
              className="relative"
              style={
                isAuthenticated
                  ? { backgroundColor: "black" }
                  : {
                      height: "100vh",
                      overflowY: "auto",
                      backgroundColor: "black",
                    }
              }
            >
              {process.env.REACT_APP_ENVIRONMENT === "qa" && (
                <span className="bg-red-500 text-white p-2 rounded absolute w-[4rem] flex left-[50%] right-[50%]">
                  Testing{" "}
                </span>
              )}

              <Routes>
                <Route index element={<Home />} />{" "}
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
                <Route path="/curriculum" element={<Curriculum />} />{" "}
                {/* <Route path="/register" element={<RegistrationForm />} />{" "} */}
                <Route element={<AuthenticatedRoutes />}>
                  <Route path="/notifications" element={<NotificationPage />} />

                  {ADMIN_ROUTES}
                </Route>
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </Layout.Content>
        </Layout>
      </div>
    </div>
  );
}

export default App;
