import React from "react";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();

  const goToPrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  return (
    <footer className="bg-black text-white py-8 mt-16">
      <div className="container mx-auto px-4 text-center">
        <div className="mb-4">
          <a
            href="mailto:mail@paygrov.com"
            className="text-orange-400 hover:text-orange-300"
          >
            Contact Us: mail@paygrov.com
          </a>
        </div>
        <div className="mb-4">
          <span
            onClick={goToPrivacyPolicy}
            className="cursor-pointer text-orange-400 hover:text-orange-300"
          >
            Privacy Policy
          </span>
        </div>
        <div>© 2024 PayGrov. All rights reserved.</div>
      </div>
    </footer>
  );
};
