import React, { useState, useEffect } from "react";
import LANDING from "../assets/mainlanding.png";
import { useNavigate } from "react-router-dom";
import { redirectToRegistrationForm } from "../utils/utils";
import { MainHeader } from "../componenets/header/MainHeader";
import { Footer } from "../componenets/footer/Footer";
import SquareWings from "../assets/square_wings.jpeg";
import { Tooltip } from "antd";

export const Home = () => {
  const [loading, setLoading] = useState(true);
  const text = "Want to become a Software Developer?";
  const loadingText = "L  O  A  D  I  N  G";

  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-black">
        <div className="text-white lg:text-2xl sm:text-xl text-base font-semibold">
          {loadingText.split("").map((char, index) => (
            <span
              key={index}
              className="loading-letter"
              style={{ animationDelay: `${index * 0.1}s`, marginRight: "10px" }}
            >
              {char === " " ? "\u00A0" : char}
            </span>
          ))}
        </div>
      </div>
    );
  }
  const offerings = [
    {
      title: (
        <>
          Foundations of <br />
          Full Stack Development
        </>
      ),
      description: (
        <ul className="list-disc">
          <li>
            Get started with the essentials of full stack development with this
            free course.
          </li>
          <li>Learn the fundamentals of front-end development.</li>
          <li>Understand back-end development concepts.</li>
          <li>Grasp data structures and their applications.</li>
        </ul>
      ),
      status: "FREE!",
      action: "View Curriculum",
    },
    {
      title: (
        <>
          Full Software Development <br /> Course
        </>
      ),
      description: (
        <>
          <p>
            Unlock the full potential of software development with this
            comprehensive course.
          </p>
          <p>Our Full Software Development Course covers:</p>
          <ul className="list-disc">
            <li>Basic Programming Fundamentals</li>
            <li>HTML, CSS, and JavaScript</li>
            <li>React JS</li>
            <li>
              C++ - Data Structures and Algorithms (Beginner to Intermediate)
            </li>
            <li>Advanced Topics in Software Development</li>
            <li>Project-Based Learning</li>
          </ul>
          <p>With job support, you will receive:</p>
          <ul className="list-disc">
            <li>Resume and Portfolio Building</li>
            <li>Mock Interviews</li>
            <li>Job Search Assistance</li>
            <li>Networking Opportunities with Industry Professionals</li>
            <li>Access to Job Listings and Referrals</li>
          </ul>
          <p>
            Prepare for a successful career in software development with expert
            instruction and hands-on projects.
          </p>
        </>
      ),
      status: "Coming Soon!",
      action: "Enroll",
      disabled: true,
    },
  ];
  return (
    <>
      <MainHeader />
      <div className="px-2 sm:px-0">
        <div className="relative  h-[calc(100vh+4rem)] sm:h-screen flex justify-start overflow-hidden fade-in">
          <img
            src={LANDING}
            alt="Landing"
            className="w-full hidden sm:block h-full object-cover filter"
          />
          <div className="absolute text-white text-3xl md:text-5xl md:ml-8 mt-[100px] md:mt-[250px] fade-in">
            <div className="flex w-full">
              <img
                src={SquareWings}
                alt="Landing"
                className="max-w-[15rem] mx-auto sm:hidden relative object-cover"
              />
            </div>

            <div className="">
              <div className="text-[32px] md:text-[65px] flex flex-col items-center gap-2">
                <div className="font-bold text-center">Want to become a </div>
                <div className="font-bold text-center">Software Developer?</div>
                <div className="text-lg w-full max-w-md text-center mt-2 p-4 text-white ">
                  <p>
                    Ready to elevate your coding skills from zero to hero? Join
                    the{" "}
                    <span className="text-orange-400 font-bold">
                      FREE SOFTWARE DEVELOPMENT COURSE
                    </span>{" "}
                    brought to you by{" "}
                    <span className="text-orange-400 font-bold">PAYGROV</span>.
                    Let's turn your coding dreams into reality!
                  </p>
                </div>
                <div
                  className="w-full max-w-md bg-orange-500 hover:bg-orange-400 text-center p-4 text-lg mt-8 cursor-pointer rounded-full"
                  onClick={redirectToRegistrationForm}
                >
                  Get Started
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center text-white mt-8 md:mt-[80px]">
          <div className="text-[32px] md:text-[55px] font-bold ">
            Unlock Your Future in{" "}
            <span className="text-orange-400">Software Development</span>
          </div>
          <div className="text-lg md:text-2xl mt-4 md:mt-12 ">
            Embark on your tech journey with PayGrov's comprehensive free
            course,
            <br />
            designed for practical learning and job placement support.
          </div>
        </div>
        <div className="text-center my-8 mt-[100px]  md:mt-[200px] mb-[100px] ">
          <div className="text-[32px] md:text-[55px] font-bold text-white">
            What we offer?
          </div>
        </div>
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 mt-6 md:mt-[5rem] px-4 md:px-[180px]">
          {offerings.map((item, index) => (
            <div
              key={index}
              className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg p-8 flex flex-col justify-between font-sans"
            >
              <div className="text-center ">
                <div className="font-bold text-white text-[24px] md:text-[32px] mb-8">
                  {item.title}
                </div>
                <p
                  className={`text-white mt-8 md:mt-16 px-4 md:px-16 text-lg text-left `}
                >
                  {item.description}
                </p>
                <div className="text-[32px] md:text-[55px] font-bold text-white mt-6">
                  {item.status}
                </div>
              </div>
              <button
                disabled={item.disabled}
                className={
                  "text-lg md:text-xl font-bold py-2 px-4 md:px-8 rounded-full self-center mt-6 " +
                  (item.disabled
                    ? "bg-orange-500/50 cursor-not-allowed "
                    : "bg-orange-500")
                }
                onClick={() => navigate("/curriculum")}
              >
                {item.action}
              </button>
            </div>
          ))}
        </div> */}

        <div id="mainCoantiner">
          <div className="margin-body">
            <div>
              <div className="starsec"></div>
              <div className="starthird"></div>
              <div className="starfourth"></div>
              <div className="starfifth"></div>
            </div>

            <div className="lg:flex items-center justify-center lg:gap-[50px] gap-10">
              <div className="col-sm-3 col-md-3 pricing-column-wrapper">
                <div className="pricing-column">
                  <div className="pricing-price-row">
                    <div className="pricing-price-wrapper">
                      <div className="pricing-price">
                        <div className="text-[35px] mt-5">FREE</div>
                        <div className="time"></div>
                      </div>
                    </div>
                  </div>
                  <div className="pricing-row-title">
                    <div className="pricing_row_title">
                      Foundations of <br />
                      Full Stack Development
                    </div>
                  </div>
                  <figure className="pricing-row">
                    Live classes for interactive learning experience
                  </figure>
                  <figure className="pricing-row">
                    Live doubt-solving sessions
                  </figure>
                  <figure className="pricing-row">
                    <span>Learn Basic Programming Fundamentals</span>
                  </figure>
                  <figure className="pricing-row">
                    Learn the fundamentals of front-end development
                  </figure>
                  <figure className="pricing-row">
                    <span>Understand back-end development concepts</span>
                  </figure>
                  <figure className="pricing-row">
                    Grasp data structures and their applications
                  </figure>
                  <figure className="pricing-row">
                    Hands-On Projects and Assignments
                  </figure>
                  <figure className="pricing-row strike">
                    Certificate of Completion
                  </figure>
                  <figure className="pricing-row strike">
                    Resume and Portfolio Building
                  </figure>
                  <figure className="pricing-row strike">
                    Mock Interviews
                  </figure>
                  <figure className="pricing-row strike">
                    Job Search Assistance
                  </figure>
                  <figure className="pricing-row strike">
                    Networking Opportunities with Industry Professionals
                  </figure>
                  <figure className="pricing-row strike">
                    Access to Job Listings and Referrals
                  </figure>
                  <div className="pricing-footer">
                    <div className="gem-button-container gem-button-position-center ">
                      <a
                        href="#"
                        className="gem-button gem-green cursor-pointer"
                        onClick={() => navigate("/curriculum")}
                      >
                        View Curriculum
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 pricing-column-wrapper"></div>
              <div className="col-sm-3 col-md-3 pricing-column-wrapper">
                <div className="pricing-column">
                  <div className="pricing-price-row">
                    <div className="pricing-price-wrapper">
                      <div className="pricing-price">
                        <div className="text-[25px] mt-5">Coming Soon</div>
                        <div className="time"></div>
                      </div>
                    </div>
                  </div>
                  <div className="pricing-row-title">
                    <div className="pricing_row_title">
                      Full Software Development <br /> Course
                    </div>
                  </div>
                  <figure className="pricing-row">
                    <span>
                      All-Inclusive Foundations of Full Stack Development Course
                    </span>
                  </figure>
                  <figure className="pricing-row">
                    <span>Advanced Topics in Software Development</span>
                  </figure>
                  <figure className="pricing-row">
                    <span>Project-Based Learning</span>
                  </figure>
                  <figure className="pricing-row">
                    <span>Deploying and Hosting Web Applications</span>
                  </figure>
                  <figure className="pricing-row">
                    <span>Lifetime Access to Course Updates</span>
                  </figure>
                  <figure className="pricing-row">
                    Interactive Q&A Sessions
                  </figure>
                  <figure className="pricing-row">
                    <span>Certificate of Completion</span>
                  </figure>
                  <figure className="pricing-row">
                    <span>Resume and Portfolio Building</span>
                  </figure>
                  <figure className="pricing-row">
                    <span>Mock Interviews</span>
                  </figure>
                  <figure className="pricing-row">
                    <span>Job Search Assistance</span>
                  </figure>

                  <figure className="pricing-row">
                    <span>
                      Networking Opportunities with Industry Professionals
                    </span>
                  </figure>
                  <figure className="pricing-row">
                    <span>Access to Job Listings and Referrals</span>
                  </figure>

                  <div className="pricing-footer">
                    <Tooltip title={"Coming Soon!"}>
                      <div className="gem-button-container gem-button-position-center !cursor-not-allowed">
                        <div className="gem-button-no text-gray-400 ">
                          Enroll
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
