import React from "react";
import { Form, Input, Radio, Select, Button } from "antd";

const { Option } = Select;

export const RegistrationForm = () => {
  const onFinish = (values) => {
    console.log("Received values:", values);
  };

  const labelStyle = { color: "white" };
  const labelCol = { span: 24 };

  return (
    <div className="bg-black">
      <div className="text-white h-full flex justify-center items-center pt-[200px]">
        <div className="w-[600px]">
          <Form
            name="application_form"
            onFinish={onFinish}
            initialValues={{ experience: "New to Programming", placed: "No" }}
            labelCol={labelCol}
            wrapperCol={{ span: 18 }} // Adjust wrapper column width if needed
          >
            <Form.Item
              name="fullName"
              label="Full Name"
              rules={[
                { required: true, message: "Please enter your full name!" },
              ]}
              style={labelStyle}
              labelCol={labelCol}
            >
              <Input placeholder="Enter your full name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter your email address!" },
              ]}
              style={labelStyle}
              labelCol={labelCol}
            >
              <Input type="email" placeholder="Enter your email" />
            </Form.Item>

            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[
                { required: true, message: "Please enter your phone number!" },
              ]}
              style={labelStyle}
              labelCol={labelCol}
            >
              <Input placeholder="Enter your phone number" />
            </Form.Item>

            <Form.Item
              name="education"
              label="Educational Background"
              rules={[
                {
                  required: true,
                  message: "Please enter your educational background!",
                },
              ]}
              style={labelStyle}
              labelCol={labelCol}
            >
              <Input placeholder="Enter your educational background" />
            </Form.Item>

            <Form.Item
              name="experience"
              label="Experience in Programming"
              rules={[
                {
                  required: true,
                  message: "Please select your experience in programming!",
                },
              ]}
              style={labelStyle}
              labelCol={labelCol}
            >
              <Radio.Group>
                <Radio value="New to Programming">New to Programming</Radio>
                <Radio value="0 - 1 Years">0 - 1 Years</Radio>
                <Radio value="1 - 2 Years">1 - 2 Years</Radio>
                <Radio value="2+ Years">2+ Years</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="placed"
              label="Are you placed?"
              style={labelStyle}
              labelCol={labelCol}
            >
              <Radio.Group>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="graduationYear"
              label="Year Of Graduation (If applicable)"
              style={labelStyle}
              labelCol={labelCol}
            >
              <Input placeholder="Enter your graduation year" />
            </Form.Item>

            <Form.Item
              name="motivation"
              label="What motivates you to join this SDE course?"
              style={labelStyle}
              labelCol={labelCol}
            >
              <Input.TextArea rows={4} placeholder="Enter your motivation" />
            </Form.Item>

            <Form.Item
              name="interests"
              label="Specific Areas of Interest within Software Development"
              style={labelStyle}
              labelCol={labelCol}
            >
              <Select mode="tags" placeholder="Select or enter your interests">
                <Option value="Web apps">Web apps</Option>
                <Option value="Mobile games">Mobile games</Option>
                <Option value="AI">AI</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="howDidYouHear"
              label="How did you hear about our course?"
              style={labelStyle}
              labelCol={labelCol}
            >
              <Input placeholder="Enter how you heard about the course" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
