import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/nav_logo.png";
import { redirectToRegistrationForm } from "../../utils/utils";

export const MainHeader = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="fixed backdrop-blur-sm bg-black top-0 w-full bg-white bg-opacity-0 py-4 px-8 z-10 fade-in">
      <div className="flex justify-between items-center text-white ">
        <div
          className="text-xl font-semibold cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={LOGO} alt="Landing" className="h-[3.2rem] sm:h-[78px]" />
        </div>
        <div className="md:hidden">
          <div className="flex items-center gap-6 relative">
            <div className="cursor-pointer text-gray-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                onClick={toggleMenu}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={
                    menuOpen
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
              {menuOpen && (
                <div className="absolute w-[15rem] right-0 top-full mt-6 bg-white shadow-md rounded-md">
                  <ul className="py-2">
                    <li
                      className="cursor-pointer bg-gray-100 px-4 py-2"
                      onClick={() => navigate("/")}
                    >
                      Home
                    </li>
                    {/* <li className="cursor-pointer bg-gray-100 px-4 py-2">
                      About
                    </li> */}
                    <li className="cursor-pointer bg-gray-100 px-4 py-2">
                      <a href="mailto:mail@paygrov.com">Contact Us</a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="hidden md:flex items-center gap-6 text-lg">
          <div
            className="cursor-pointer hover:text-gray-400"
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </div>
          {/* <div className="cursor-pointer hover:text-gray-400">About</div> */}
          <div className="cursor-pointer hover:text-gray-400">
            <a href="mailto:mail@paygrov.com">Contact Us</a>
          </div>
          <div className="cursor-pointer hover:text-gray-400">
            <button
              onClick={() => {
                redirectToRegistrationForm();
              }}
              className="blinking-button bg-orange-500 text-white font-semibold py-2 px-4 rounded-full"
            >
              Register Now
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
