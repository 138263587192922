import { Avatar, Button, Layout, Select, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../redux/services/authService";
import { NotificationNavItem } from "../notification-nav-item/NotificationNavItem";
import { UserOutlined } from "@ant-design/icons";

import { formateDate } from "../../utils/convertSlotToString";
import { usePostApi } from "../../hooks/usePostApi";
import { useEffect } from "react";
import { updateUser } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { USER_BASED_ROUTES } from "../../constants";

const ALLOWED_ROLES = ["admin"];

export const Sidebar = ({ menu }) => {
  const { user } = useSelector((state) => state.auth);
  const userType = user.user.type;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data, postData, resetState } = usePostApi(
    `${process.env.REACT_APP_BASE_URL}/api/change-user-role/${user.user.user}/`
  );

  const filteredRolesExcludingVendor = user?.roles?.filter((role) =>
    ALLOWED_ROLES.includes(role)
  );

  useEffect(() => {
    if (data) {
      navigate(USER_BASED_ROUTES[data?.user?.type][0]);
      dispatch(updateUser(data));
      resetState();
    }
  }, [data, dispatch, resetState, navigate]);

  return (
    <Layout.Sider
      style={{
        width: "263px",
        // backgroundColor: "#0a80b6",
        // color: "white",
      }}
      className="min-h-screen border-0 border-r sidebar"
      breakpoint={"lg"}
      theme="light"
      collapsedWidth={0}
      trigger={null}
    >
      <div className="px-[16px] py-[12px] flex flex-col h-full">
        <div
          style={{
            padding: "24px 8px 12px 8px",
          }}
        >
      
        </div>
        {menu}
        {filteredRolesExcludingVendor?.length <= 1 &&
          user?.user?.type === "pmo" && <div className="mt-auto"></div>}
        {/* <NotificationNavItem /> */}
        <div
          className={
            filteredRolesExcludingVendor?.length <= 1 &&
            user?.user?.type === "pmo"
              ? ""
              : "mt-auto"
          }
        >
          {filteredRolesExcludingVendor?.length > 1 && (
            <div className="flex gap-2 justify-between items-center py-2 mt-2 border-t ">
              User:
              <Select
                onChange={(role) =>
                  postData({
                    user_role: role,
                  })
                }
                value={user?.user?.type}
                options={filteredRolesExcludingVendor?.map((role) => ({
                  label: role.toUpperCase(),
                  value: role,
                }))}
              ></Select>
            </div>
          )}
          <div className="flex items-center gap-[12px]  border-0 pt-2 border-t">
            <Avatar
              icon={
                <UserOutlined className="flex justify-center p-1 text-black" />
              }
              size={30}
            />

            <div className=" max-w-[130px]">
              <p className="text-ellipsis whitespace-nowrap	 overflow-hidden m-body4 text-text-1 font-medium capitalize">
                {user.name ? (
                  user.name
                ) : (
                  <span className="capitalize">
                    {user.first_name} {user.last_name}
                  </span>
                )}
              </p>
              <p className="text-ellipsis whitespace-nowrap	 overflow-hidden text-text-4 font-normal">
                {user.email}
              </p>
            </div>
            <Tooltip title="Logout">
              <Button
                onClick={() => dispatch(logout())}
                type="text"
                className="m-icon-btn"
              >
                <LogoutIcon />
              </Button>
            </Tooltip>
          </div>
          <div className="mt-[.5rem]">
            <span className="font-semibold">Last login:</span>{" "}
            <span>{formateDate(user?.last_login)}</span>
            <p className="text-xs mt-1 text-text-4">
              &copy; 2024 Inmuto Consulting Pvt Ltd
            </p>
            {"  "}
          </div>
        </div>
      </div>
      {/* </div> */}
    </Layout.Sider>
  );
};
